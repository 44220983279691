import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-formalight',
  templateUrl: './formalight.component.html',
  styleUrls: ['./formalight.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FormalightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
