import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-endypro',
  templateUrl: './endypro.component.html',
  styleUrls: ['./endypro.component.css']
})
export class EndyproComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
