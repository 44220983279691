import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mtone',
  templateUrl: './mtone.component.html',
  styleUrls: ['./mtone.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MtoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
