import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-c2-i3',
  templateUrl: './c2-i3.component.html',
  styleUrls: ['./c2-i3.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class C2I3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
