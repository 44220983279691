import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-c2-i1',
  templateUrl: './c2-i1.component.html',
  styleUrls: ['./c2-i1.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class C2I1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
