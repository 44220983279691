import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Invitado } from '../Invitado';
import { Sorteado } from '../Sorteado';


@Component({
  selector: 'app-sorteo',
  templateUrl: './sorteo.component.html',
  styleUrls: ['./sorteo.component.css']
})
export class SorteoComponent implements OnInit {

  ya_registrado: boolean = false;
  ya_invitado: boolean = false;

  estados = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Coahuila',
    'Ciudad de México',
    'Durango',
    'Estado de México',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];

  registro: Sorteado = {
    nombre: '',
    apellido: '',
    especialidad: '',
    cedula: '',
    telefono: '',
    correo: '',
    entidad: ''
  };

  invitado: Invitado = {
    nombre: '',
    apellido: '',
    especialidad: '',
    cedula: '',
    telefono: '',
    correo: '',
    entidad: ''
  }

  constructor() { }

  ngOnInit() {
  }

  enviarDatos(form: NgForm){
    if(form.valid){
      console.log(JSON.stringify(this.registro));
      form.resetForm();
    }
  }

}
