import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../auth.service';
import { Respuesta } from '../Respuesta';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.css']
})
export class ConsultaComponent implements OnInit {

  
    nombre: String = "";
    email: String = "";
    telefono: String = "";
    especialidad: String = "";
    pais: String = "";
    estado: String = "";
    ciudad: String = "";
    mensaje: String = "";

  constructor(private _authService: AuthService ,private _snackbar: MatSnackBar) {
  }

  ngOnInit() {
  }

  enviarConsulta(form:NgForm){
    let datos = {
      nombre:this.nombre,
      email:this.email,
      telefono:this.telefono,
      especialidad:this.especialidad,
      pais:this.pais,
      estado:this.estado,
      ciudad:this.ciudad,
      mensaje:this.mensaje
    }
    if(form.valid){
      this._authService.enviarEmail(datos).subscribe(
        res => {
          if((<Respuesta>res).mensaje==='enviado'){
            this._snackbar.open("Consulta enviada",'Cerrar',{
              duration: 5000
            });
            form.resetForm();
          }
          else{
            this._snackbar.open("Ocurrio un error",'Cerrar',{
              duration: 5000
            });
          }
        }
      );
    }
  }

}
