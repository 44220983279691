import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-endyp',
  templateUrl: './endyp.component.html',
  styleUrls: ['./endyp.component.css']
})
export class EndypComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
