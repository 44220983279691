import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-slimspec',
  templateUrl: './slimspec.component.html',
  styleUrls: ['./slimspec.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SlimspecComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
