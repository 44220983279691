import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-quadrostar',
  templateUrl: './quadrostar.component.html',
  styleUrls: ['./quadrostar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuadrostarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
